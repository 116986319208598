import React, { useState } from 'react';

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    console.log('Usuário:', username);
    console.log('Senha:', password);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>CRGNET</h1>
      </header>
      <div className="login-container">
        <form onSubmit={handleLogin}>
          <div className='top1'>
            <label htmlFor="username">Usuário:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Entrar</button>
        </form>
        <p>
          <a href="#">Esqueci minha senha</a>
        </p>
      </div>
    </div>
  );
}

export default App;
